@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #757575;
  --background-color: #282c34;
  --header-background-color: #909090;
  --text-color: white;
  --link-color: #61dafb;
  --hover-color: #21a1f1;
  --font-family: 'Arial', sans-serif;
}
 

body {
  font-family: var(--font-family);
  background-color: #f4f4f9;
  margin: 20px;
  margin-top: 20px;
  padding: 20px;
  display: flex;
  justify-content: center;
  height: 100vh;
}


.oswald-200 {
  font-family: "Oswald", sans-serif;
  font-weight: 200;
}

.oswald-300 {
  font-family: "Oswald", sans-serif;
  font-weight: 300;
}

.oswald-400 {
  font-family: "Oswald", sans-serif;
  font-weight: 400;
}

.oswald-500 {
  font-family: "Oswald", sans-serif;
  font-weight: 500;
}

.oswald-600 {
  font-family: "Oswald", sans-serif;
  font-weight: 600;
}

.oswald-700 {
  font-family: "Oswald", sans-serif;
  font-weight: 700;
}
